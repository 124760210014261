import React, { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import Container from '@mui/material/Container';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { SvgIcon } from "@mui/material";
import { ReactComponent as Horse } from '../components/img/horse.svg';
import { ReactComponent as Harness } from '../components/img/harness.svg';
import { ReactComponent as Hound } from '../components/img/hound.svg';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import horseavatar from '../components/img/horse-race.jpeg'
import greyhoundavatar from '../components/img/hound-race.jpeg'
import harnessavatar from '../components/img/harness-race.jpeg'


const BettingTable = () => {

const [bettingtype, setBettingtype] = React.useState('horse');

const [data, setData] = React.useState([]);


const loadData =() => {
  (async () => {
    var fetchURL = new URL('https://staging.bucklit.com.au/betting/list.php?category=horse');
    if (bettingtype === 'greyhound') {
      fetchURL = new URL('https://staging.bucklit.com.au/betting/list.php?category=greyhound');
    }
    if (bettingtype === 'harness') {
      fetchURL = new URL('https://staging.bucklit.com.au/betting/list.php?category=harness');
    }
    var response = await fetch(fetchURL.href);
    setData(await response.json());
  })();
}

const handlleBettingType = (event, newBettingtype) => {
  setBettingtype(newBettingtype);
  loadData();
};

useEffect(()=>{
  loadData();
}, [bettingtype]) // <-- empty dependency array


  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorKey: 'raceid',
        header: 'Race ID',
        footer: 'Race ID',
      },
      {
        accessorKey: 'datetimestr',
        header: 'Race',
        footer: 'Race',
      },
      {
        accessorKey: 'odd',
        header: 'Odd',
        footer: 'Odd',
      },
      {
        accessorKey: 'even',
        header: 'Even',
        footer: 'Even',
      },
      {
        accessorKey: 'score',
        header: 'Score',
        footer: 'Score',
      },
      {
        accessorKey: 'url',
        header: 'URL',
        footer: 'URL',
      },
    ],
    [],
    //end
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableBottomToolbar: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enablePagination: false,
    muiTableContainerProps: { sx: { maxHeight: '400px' } },
    muiTableBodyCellProps: {
      sx: (theme) => ({
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.grey[900]
            : theme.palette.grey[50],
      }),
    },
  });

  return  (
    <Container
      id="horses"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" sx={{ color: 'text.primary' }}>
          Todays Racing
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          List of top valued bettings based on Kelly Criterion Score
        </Typography>


        <ToggleButtonGroup
              value={bettingtype}
              exclusive
              onChange={handlleBettingType}
              aria-label="select bettings"
            >
              <ToggleButton value="horse" aria-label="horse">
                <SvgIcon><Horse></Horse></SvgIcon>
              </ToggleButton>
              <ToggleButton value="greyhound" aria-label="greyhound">
              <SvgIcon><Hound></Hound></SvgIcon>
              </ToggleButton>
              <ToggleButton value="harness" aria-label="harness">
              <SvgIcon><Harness></Harness></SvgIcon>
              </ToggleButton>
        </ToggleButtonGroup> 

      </Box>
      <Grid container spacing={2}>
        {data.map((betting, index) => (
          <Grid item xs={12} sm={6} md={3} key={index} sx={{ display: 'flex' }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  pr: 2,
                }}
              >
                {bettingtype == 'horse' &&
                <img src={horseavatar} ></img>
                }
                {bettingtype == 'greyhound' &&
                <img src={greyhoundavatar} ></img>
                }
                {bettingtype == 'harness' &&
                <img src={harnessavatar} ></img>
                }
                
                 
              </Box>


              <CardContent>
                <Typography variant="h6" sx={{ color: 'text.primary' }}>
                  RaceID: {betting.raceid}
                </Typography>
                <Typography variant="body1" sx={{ color: 'text.primary' }}>
                 <strong>Chances to win the bet based on Kelly Criterion Score:</strong> {Math.round(betting.score*10)}% <progress value={betting.score*10/100} />
                </Typography>
                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                <strong>Odd/Even:</strong> {betting.odd}/{betting.even}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  <Link href={betting.url}>Place bet - Sportbets</Link>
                </Typography>

              </CardContent>

            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default BettingTable;